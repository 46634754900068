import React, { FC } from 'react';
import classNames from 'classnames';
import { graphql } from 'gatsby';

import GatsbyImage from 'common/GatsbyImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import ListPlusAccordion from 'common/ListPlusAccordion';

import BannerButtons from './BannerButtons';
import { IWideBannerProps } from './model';

import './WideBanner.scss';

const WideBanner: FC<IWideBannerProps> = ({
  image,
  description = '',
  cta = [],
  alignImage,
  gridList,
  color,
  accordion,
  isButtonsAtTheBottom,
  sectionTheme,
}) => {
  const hasGridList = gridList?.length > 0;
  const [position] = alignImage || ['left'];
  const { label } = color || {};
  const isTransparentTheme = label === 'transparent';
  const isDarkTheme = label === 'blue' || isTransparentTheme;
  const { image: bannerImage, imageAlt } = image?.[0]?.properties || {};

  return (
    <div
      className={classNames('wide-banner', {
        'wide-banner--reverse': position === 'left',
        'wide-banner--column': position === 'bottom',
        [`wide-banner--bg-${label}`]: label,
        'wide-banner--dark-theme': isDarkTheme,
        'wide-banner--transparent-theme': isTransparentTheme,
        [`${sectionTheme}`]: sectionTheme,
      })}
    >
      <div
        className={classNames('wide-banner__info', {
          'wide-banner__info--start': hasGridList,
          'wide-banner__info--dark-theme': isDarkTheme,
        })}
      >
        {description ? (
          <DangerouslySetInnerHtml className="wide-banner__description" html={description} />
        ) : null}
        {gridList?.length ? (
          <ListPlusAccordion {...{ ...gridList[0].properties, accordion }} />
        ) : null}
        {cta?.length && !isButtonsAtTheBottom ? <BannerButtons cta={cta} /> : null}
      </div>
      {bannerImage && (
        <div className="wide-banner__image">
          <GatsbyImage
            image={bannerImage}
            alt={imageAlt}
            isLazyLoading={false}
            fadeIn={false}
            useFocalPoint
          />
        </div>
      )}
      {cta?.length && isButtonsAtTheBottom ? <BannerButtons cta={cta} /> : null}
    </div>
  );
};

export const query = graphql`
  fragment FragmentWideBanner on TInfoBannerWide {
    properties {
      showInMenu
      anchorName
      alignImage
      cta {
        properties {
          ariaLabel
          link {
            name
            target
            url
            queryString
          }
        }
      }
      color {
        label
      }
      description
      image {
        properties {
          imageAlt
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
      accordion {
        properties {
          ariaLabel
          content {
            properties {
              text
            }
          }
          label
        }
      }
      gridList {
        properties {
          infoList {
            properties {
              description
              icon
              iconAlignment
            }
          }
        }
      }
      isButtonsAtTheBottom
      sectionTheme
    }
    structure
  }
`;

export default WideBanner;
