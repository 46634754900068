import React, { FC } from 'react';
import classNames from 'classnames';

import ControlledAccordion from 'common/ControlledAccordion';
import IconPlusTextGrid from 'common/IconPlusTextGrid';
import GatsbyImage from 'common/GatsbyImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import Button from 'common/Button';

import './ListPlusAccordion.scss';
import { graphql } from 'gatsby';
import { IListAndAccordionItem } from '@shared/types/umbraco';

const ListPlusAccordion: FC<IListAndAccordionItem> = ({
  infoList,
  accordion,
  columnsCount,
  btnTitle,
  ariaMore,
  roundImage,
  fileLink,
  additionalText,
  cta,
  imageCheck,
  bottomText,
  sectionTheme,
}) => (
  <div
    data-testid="list"
    className={classNames('list-flex', {
      [`list--${sectionTheme}`]: sectionTheme,
    })}
  >
    <div className="list-container-wrapper">
      <div className="list-container">
        {infoList && (
          <IconPlusTextGrid {...{ infoList, columnsCount, btnTitle, ariaMore, roundImage }} />
        )}
        {accordion?.length ? <ControlledAccordion items={accordion} /> : null}
      </div>
      {imageCheck?.length ? (
        <div className="list__image">
          <GatsbyImage
            image={imageCheck[0].properties.image}
            alt={imageCheck[0].properties.imageAlt}
            className="list__image-img"
            isLazyLoading={false}
            fadeIn={false}
          />
        </div>
      ) : null}
    </div>
    {fileLink?.length ? (
      <div className="list__file">
        <Button
          classes="list__file-btn"
          to={fileLink[0].properties.link.url}
          ariaLabel={fileLink[0].properties.ariaLabel || ''}
          target="_blank"
          variant="link"
        >
          {fileLink[0]?.properties?.name}
        </Button>
      </div>
    ) : null}
    <DangerouslySetInnerHtml className="list__additionalText" html={additionalText} />
    {cta?.length ? (
      <div className="list__link">
        <Button
          to={
            cta[0].properties.link[0].queryString
              ? `${cta[0].properties.link[0].url}${cta[0].properties.link[0].queryString}`
              : cta[0].properties.link[0].url
          }
          ariaLabel={cta[0].properties.ariaLabel || cta[0].properties.link[0].name}
          variant="primary"
          data-anchor={cta[0].properties.link[0].queryString || ''}
        >
          {cta[0].properties.link[0].name}
        </Button>
      </div>
    ) : null}
    <DangerouslySetInnerHtml className="list__bottomText" html={bottomText} />
  </div>
);

export const query = graphql`
  fragment FragmentListPlusAccordionProps on TListAndAccordion {
    properties {
      accordion {
        properties {
          ariaLabel
          content {
            properties {
              text
            }
          }
          label
        }
      }
      infoList {
        properties {
          description
          icon
        }
      }
      title
    }
  }
`;

export default ListPlusAccordion;
