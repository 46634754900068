import React, { FC } from 'react';

import Button from 'common/Button';

import { IBannerButtons } from '../model';

const BannerButtons: FC<IBannerButtons> = ({ cta }) => (
  <div className="wide-banner__buttons">
    {cta.map(({ properties: { link, ariaLabel = '' } }, idx) => {
      const linkItem = link?.[0];

      return (
        <Button
          to={linkItem?.queryString ? `${linkItem?.url}${linkItem?.queryString}` : linkItem?.url}
          key={linkItem?.name}
          ariaLabel={ariaLabel}
          variant={idx === 0 ? 'primary-link' : 'secondary-link'}
          iconSuffix="chevron-right"
          target={linkItem?.target}
        >
          {linkItem?.name}
        </Button>
      );
    })}
  </div>
);

export default BannerButtons;
