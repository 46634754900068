import { IIconPlusTextGrid } from '@shared/types/umbraco';
import classNames from 'classnames';
import Button from 'common/Button';
import Icon from 'common/IconCustom';
import ImageCard from 'common/ImageCard';
import useScreenRecognition from 'hooks/useScreenRecognition';
import { DangerouslySetInnerHtml } from 'layout';
import React, { FC, useEffect, useMemo, useState } from 'react';
import './IconPlusTextGrid.scss';

const IconPlusTextGrid: FC<IIconPlusTextGrid> = ({
  infoList,
  columnsCount,
  btnTitle,
  ariaMore,
  roundImage,
}) => {
  const { isMobile } = useScreenRecognition();
  const [toShow, setToShow] = useState<number>(0);
  const stepLoad = useMemo(() => Number(columnsCount?.[0] || 3), [columnsCount?.[0]]);

  useEffect(() => {
    setToShow(stepLoad);
  }, [stepLoad]);

  const withImage = infoList?.some((element) => element?.properties?.image !== null);
  const listToShow = isMobile && !!btnTitle ? infoList?.slice(0, toShow) : infoList;

  const loadMore = () => {
    setToShow((prevValue) => prevValue + stepLoad);
  };

  return (
    <>
      <div
        className={classNames('icon-text__grid', {
          [`icon-text__grid--${columnsCount?.[0]}-columns`]: !!columnsCount?.length,
          [`icon-text__grid--withImage`]: withImage,
          [`icon-text__grid--round-image-card`]: roundImage,
        })}
      >
        {listToShow?.length
          ? listToShow.map(({ properties: { icon, description, iconAlignment, image } }) =>
              image ? (
                <ImageCard key={description.length} {...{ image, description }} />
              ) : (
                <div
                  key={description.length}
                  className={classNames('icon-text__grid__item', {
                    [`icon-text__grid__item--${iconAlignment}`]: !!iconAlignment,
                  })}
                >
                  <Icon icon={icon?.[0]} />
                  {description ? (
                    <DangerouslySetInnerHtml
                      className="icon-text__grid__item__description"
                      html={description}
                    />
                  ) : null}
                </div>
              )
            )
          : null}
      </div>
      {isMobile && !!btnTitle && toShow < infoList?.length ? (
        <div className="icon-text__grid__button">
          <Button
            clickHandler={loadMore}
            classes="load-more__button"
            variant="secondary"
            ariaLabel={ariaMore}
          >
            {btnTitle}
          </Button>
        </div>
      ) : null}
    </>
  );
};
export default IconPlusTextGrid;
